const lightNavbar = () => {
  document.getElementById('navbar').classList.remove('navbar-light');
  document.getElementById('navbar').classList.add('navbar-dark');
}

const darkNavbar = () => {
  document.getElementById('navbar').classList.remove('navbar-dark');
  document.getElementById('navbar').classList.add('navbar-light');
}
if (typeof window !== 'undefined') {
  window.removeEventListener('resize', this.setChartDimensions)
  window.addEventListener('scroll', (x) => {
    if (window.scrollY > 5) {
      lightNavbar();
    } else {
      darkNavbar()
    }
  });
}
