/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, {Component} from "react";
import chessIcon from '../images/chess.png';
import analysisIcon from '../images/analysis.png';
import currencyIcon from '../images/currency.png';
import barsIcon from '../images/bars-chart.png';


class Services extends Component {

  state = {
    selected: 'chess'
  }

  render() {

    const { selected } = this.state;
    return (
        <>
          <section id="services" class="section section_menu section_gray">
            <div class="container">
              <div class="row">
                <div class="col">
                  <h2 class="section__heading text-center">
                    Our services
                  </h2>
                  <p class="section__subheading text-center">
                    We advice our clients on the optimal way how to manage their financial assets. Our advice
                    is built on a solid experience in asset management and on a network of selected wealth
                    management professionals. Andromeda Advisory offers its clients services that includes discretionary
                    management
                    and investment advice as well as assistance in family office services.
                  </p>
                </div>
              </div>

              <div class="row justify-content-lg-center section_reservation__row">
                <div class="col">

                  <div class="section_menu__nav">
                    <ul>
                      <li class={selected === 'chess' ? "active" : null}>
                        <a class="" data-filter=".mains" onClick={()=> this.setState({selected: 'chess'})}>
                          <img src={chessIcon} class="card-img-top mx-auto d-block" alt="chess icon"/>
                        </a>
                      </li>
                      <li class={selected === 'currency' ? "active" : null}>
                        <a class="" data-filter=".lunch" onClick={()=> this.setState({selected: 'currency'})}>
                          <img src={currencyIcon} class="card-img-top mx-auto d-block" alt="currency icon"/>
                        </a>
                      </li>
                      <li class={selected === 'bars' ? "active" : null}>
                        <a class="" data-filter=".dinner" onClick={()=> this.setState({selected: 'bars'})}>
                          <img src={barsIcon} class="card-img-top mx-auto d-block" alt="bar chart icon"/>
                        </a>
                      </li>
                      <li class={selected === 'analysis' ? "active" : null}>
                        <a class="" data-filter=".drinks" onClick={()=> this.setState({selected: 'analysis'})}>
                          <img src={analysisIcon} class="card-img-top mx-auto d-block" alt="research icon"/>
                        </a>
                      </li>
                    </ul>
                  </div>

                </div>
              </div>

              <div class="row section_menu__grid" id="menu_images">

                {selected === 'chess' ? (
                <div class="col section_menu__grid__item mains">
                  <div class="container section_menu__item">
                    <div class="row">
                      <div class="card-body">
                        <h4>Discretionary Management</h4>
                        <p>
                          Once a client's attitude to risk is established, we take into account his/her circumstances
                          and objectives in
                          order to create a bespoke portfolio. Responsibility for the day to day management of the
                          portfolio is left in our hands and
                          we maintain regular contact with clients to keep them informed about the performance.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                  ) : null }

                {selected === 'currency' ? (
                <div class="col section_menu__grid__item lunch">
                  <div class="container section_menu__item">
                    <div class="row">
                      <div class="card-body">
                        <h4>Investment Advice</h4>
                        <p>
                          Some clients wish to take a more active role in the running of their portfolios. Our
                          investment advisory
                          service is perfect for them. We help them develop their individual strategy and then make sure
                          their orders are carried out and
                          monitored efficiently. Moreover, we provide them with regular portfolio analysis and risk
                          management.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                ) : null }


                {selected === 'bars' ? (
                <div class="col section_menu__grid__item dinner">
                  <div class="container section_menu__item">
                    <div class="row">
                      <div class="card-body">
                        <h4>Assistance in Family office services</h4>
                        <p>
                          Alongside the classic range of asset management services, Andromeda Advisory directs its
                          clients - according
                          to their specific needs - to appropriate national and international specialists in financial,
                          tax and succession planning.
                          These services ensure that we consider out clients' complex financial requirements and needs
                          from a holistic perspective.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                ) : null }


                {selected === 'analysis' ? (
                <div class="col section_menu__grid__item drinks">
                  <div class="container section_menu__item">
                    <div class="row">
                      <div class="card-body">
                        <h4>Discretionary Management</h4>
                        <p>
                          Once a client's attitude to risk is established, we take into account his/her circumstances
                          and objectives in
                          order to create a bespoke portfolio. Responsibility for the day to day management of the
                          portfolio is left in our hands and
                          we maintain regular contact with clients to keep them informed about the performance.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                ) : null }

              </div>
            </div>
          </section>
        </>
    )
  }
}
export default Services
