/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, {Component} from "react";

class Contact extends Component {
  sendMail = async (e) => {
    e.preventDefault();
    const form = new FormData(document.getElementById('reservation__form'));
    fetch("https://getform.io/f/a037b325-8b72-42b8-a51c-21cc49400404", {
      method: "POST",
      body: form
    }).then((result) => {
      document.getElementById('success_message').style.display = 'block';
      document.getElementById('reservation__form').reset();
    });
  }

  render() {
    return (
        <>
          <section id="contactus" className="section section_reservation section_gray" id="section_reservation">
            <div className="container">
              <div className="row">
                <div className="col">


                  <h2 className="section__heading text-center">
                    Contact Us
                  </h2>

                  <p className="section__subheading text-center">
                    Contact us to see how our experts can save you time and money with our personalised
                    approach.
                  </p>

                </div>
              </div>
              <div className="row justify-content-lg-center  section_reservation__row">
                <div className="col-lg-8">
                  <form className="section_reservation__form" id="reservation__form">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="sr-only" htmlFor="reservation__form__name">Full
                            name</label>
                          <input type="text" className="form-control" id="name"
                                 name="name" placeholder="Full name"/>
                          <div className="invalid-feedback"></div>
                        </div>
                        <div className="form-group">
                          <label className="sr-only" htmlFor="reservation__form__phone">Phone
                            number</label>
                          <input type="tel" className="form-control" id="phone"
                                 name="phone" placeholder="Phone number"/>
                          <div className="invalid-feedback"></div>
                        </div>
                        <div className="form-group">
                          <label className="sr-only" htmlFor="reservation__form__email">E-mail
                            address</label>
                          <input type="email" className="form-control"
                                 id="email" name="email"
                                 placeholder="E-mail address" required/>
                          <div className="invalid-feedback"></div>
                        </div>

                      </div>
                      <div className="col-md-6">
                                            <textarea className="textarea form-control" cols="40" id="message"
                                                      name="message" rows="8" placeholder="Your message..."/>
                      </div>
                      <div className="col">

                        <div className="text-center">
                          <button className="btn btn-primary" onClick={this.sendMail}>
                            Send
                          </button>
                        </div>

                      </div>
                    </div>
                  </form>
                  <div id="error_message" style={{width: '100%', height: '100%', display: 'none'}}>
                    <h4>Error</h4>
                    Sorry there was an error sending your form.
                  </div>
                  <div id="success_message" style={{width: '100%', height: '100%', display: 'none'}}>
                    <h2>Success! Your Message was sent successfully.</h2>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
    )
  }
}

export default Contact
