import React from "react"
import { Link } from "gatsby"

import Services from "../components/services"
import Contact from "../components/contact"
import SEO from "../components/seo"
import Mountain from "../images/background2.jpg"
import Logo from "../images/logo-white.png"

import '../components/navbar';

const IndexPage = () => (
  <>
    <SEO title="Andromeda Advisory" />
    <nav id={"navbar"} className="navbar navbar-light navbar-expand-lg fixed-top">
    <div className="container">

      <div className="navbar-header d-lg-none" href="index.html">
      </div>

      <button className="navbar-toggler" type="button" data-toggle="collapse"
      data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
      aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className="navbar-collapse collapse" id="navbarSupportedContent" >

        <ul className="navbar-nav mr-auto">
          <li className="nav-item ">
            <a className="nav-link" href="#home">Home</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#aboutus">About us</a>
          </li>
        </ul>

        <div className="navbar-brand d-none d-lg-flex" href="#home">
        </div>

        <ul className="navbar-nav ml-auto">
          <li className="nav-item">
            <a className="nav-link" href="#services">Services</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#contactus">Contact us</a>
          </li>
        </ul>

      </div>
    </div>
  </nav>


  <section id="home" className="section section_welcome" data-parallax="scroll" >

    <div className="section_welcome__main">
      <div className="container">
        <div className="row align-items-center">
          <div className="col">

            <h1 className="section__heading section_welcome__heading text-center">
              Together we can reach the top
            </h1>
            <p className="section__subheading section_welcome__subheading text-center text-muted">
              A leader in research and asset management
            </p>

          </div>
        </div>
      </div>
    </div>
</section>

    <section id="aboutus" className="section section_info section_info_opposite">
      <div className="container">
        <div className="row row-about-us">
          <h2 className="section__heading ">About Us </h2>
        </div>
        <div className="about-us">
          <div className="col">
              <p className="lead text-heading"> Andromeda Advisory is a Geneva based asset management company.
                It is a member of the Swiss Association of Asset Managers (SAMM), the leading association
                of independent managers in Switzerland. We adhere to SAAM’s Code of Ethics and Professional
                Conduct and our business activities are monitored by its supervisory authority.</p>
                <p> At Andromeda Advisory, we know how essential it is to establish close,
                long term working relationship with our client, ensuring that we fully understand
                their objectives and requirements and meet their needs. We believe that our tailored
                and transparent approach, couples with a long term outlook is the best route to achieving
                positive results in preserving and enhancing our clients’ wealth.</p>
              <br />
              <p> <a href="mailto:contact-us@andromeda-advisory.ch" className="btn btn-primary"> Contact Us </a></p>
          </div>
          <div className="col">
            <img className="wrapper" src={Mountain} alt="mountains"/>
          </div>
        </div>
      </div>
    </section>
    <Services />

    <section className="section section_map">
    <div className="section_map__map" >
      <div className="map-responsive">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2761.37968823446!2d6.146903515582349!3d46.2029013791166!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478c652e89b3e071%3A0xfc5c9fb6d796b213!2sRue%20de%20la%20Croix-d&#39;Or%2017A%2C%201204%20Gen%C3%A8ve!5e0!3m2!1sen!2sch!4v1588840540210!5m2!1sen!2sch"
        width="800" height="1000" frameborder="0" style={{ border:0 }} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
      </div>
    </div>
  </section>
  <Contact />

  <footer className="section section_footer">
    <div className="container">
      <div className="row">
        <div className="col-sm-4">


        <img src={Logo} alt="logo" className="about-us-logo" />

        </div>
        <div className="col-sm-4">


          <h5 className="section_footer__heading">
            Contact Information
          </h5>
          <ul className="section_footer__info">
            <li>
              <i className="fa fa-map-pin"></i> Rue de la Croix-d'Or, 17A CH-1204 Geneva
            </li>
            <li>
              <i className="fa fa-phone"></i> tel.: +41 (0) 22 810 88 44 <br />
                                           fax.: +41 (0) 22 810 88 45
            </li>
            <li>
              <i className="fa fa-envelope"></i> <a href="mailto:contact-us@andromeda-advisory.ch">contact-us@andromeda-advisory.ch</a>
            </li>
          </ul>

        </div>
        <div className="col-sm-4">


          <h5 className="section_footer__heading">
            Heures d'ouverture
          </h5>
          <div className="section_footer__open">
            <div className="section_footer__open__days">Lundi – Vendredi</div>
            <div className="section_footer__open__time">10:00 AM - 11:00 PM</div>
          </div>
          <div className="section_footer__open">
            <div className="section_footer__open__days">Samedi</div>
            <div className="section_footer__open__time">12:00 AM - 03:00 AM</div>
          </div>

        </div>
      </div>
    </div>
  </footer>


    <script src="../components/navbar.js"/>

  </>
)

export default IndexPage
